import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { AuthContext } from "../context/AuthContext";
import { DataContext } from "../context/DataContext";
import notify from "../utils/notify";
import { updateUserStatus } from "../api";

const DashboardAdminTalents = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { auth } = useContext(AuthContext);
  const { talents, getAllTalentList } = useContext(DataContext);

  const fetchTalents = async () => {
    await getAllTalentList();
    setLoading(false);
  };

  useEffect(() => {
    if (!auth || !auth?.admin) {
      navigate("/", { replace: true });
    } else {
      fetchTalents();
    }
    return () => setLoading(true);
  }, []);

  const handleStatusUpdate = async (e, data) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const { account_id, status } = data;
      const payload = {
        status: status === "active" ? "disabled" : "active",
      };
      const response = await updateUserStatus(account_id, "Talent", payload);
      if (response) {
        notify("Status updated successfully", "success");
        setLoading(true);
        await fetchTalents();
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25">
          <div className="container">
            <div className="mb-8 mb-sm-18">
              <div className="row mb-7 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">Talents</h3>
                </div>
              </div>
              <div className="bg-white shadow-8 rounded p-4 py-sm-7 px-sm-11">
                <div className="row">
                  <div className="col-12 order-2 order-xl-1">
                    <div className="table-responsive pb-14">
                      <table className="table table-striped mt-3">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            >
                              Title
                            </th>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            >
                              Experience
                            </th>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            >
                              Location
                            </th>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            >
                              Wallet ID
                            </th>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            [1, 2, 3, 4, 5].map((index) => (
                              <tr key={index} className="border border-color-2">
                                <th className="table-y-middle pl-6 border-0 py-7 min-width-px-235">
                                  <Skeleton width={200} height={26} />
                                </th>
                                <td className="table-y-middle py-7 min-width-px-135">
                                  <Skeleton width={200} height={26} />
                                </td>
                                <td className="table-y-middle py-7 min-width-px-135">
                                  <Skeleton width={150} height={26} />
                                </td>
                                <td className="table-y-middle py-7 min-width-px-125">
                                  <Skeleton width={70} height={26} />
                                </td>
                                <td className="table-y-middle py-7 min-width-px-155">
                                  <Skeleton width={100} height={26} />
                                </td>
                                <td className="table-y-middle py-7">
                                  <Skeleton width={70} height={26} />
                                </td>
                                <td className="table-y-middle py-7 min-width-px-80">
                                  <Skeleton width={70} height={26} />
                                </td>
                              </tr>
                            ))
                          ) : talents.length > 0 ? (
                            talents.map((talent, index) => (
                              <tr key={index} className="border border-color-2">
                                <th
                                  scope="row"
                                  className="table-y-middle pl-6 border-0 py-7 min-width-px-235"
                                >
                                  <div className="">
                                    <Link
                                      to={`/candidate-profile/${talent.account_id}`}
                                      state={{ id: talent.account_id }}
                                      className="font-size-4 mb-0 font-weight-semibold text-black-2"
                                    >
                                      {`${talent?.firstname} ${talent?.lastname}`}
                                    </Link>
                                  </div>
                                </th>
                                <td className="table-y-middle py-7 min-width-px-135">
                                  <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                    {talent?.title}
                                  </h3>
                                </td>
                                <td className="table-y-middle py-7 min-width-px-125">
                                  <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                    {talent?.experience}
                                  </h3>
                                </td>
                                <td className="table-y-middle py-7 min-width-px-155">
                                  <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                    {talent?.city}
                                    {", "}
                                    {talent?.country}
                                  </h3>
                                </td>
                                <td className="table-y-middle py-7 min-width-px-110">
                                  <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                    {talent?.account_id}
                                  </h3>
                                </td>
                                <td className="table-y-middle py-7">
                                  <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                    {talent?.status}
                                  </h3>
                                </td>
                                <td className="table-y-middle py-7 min-width-px-80">
                                  <div className="d-flex">
                                    <button
                                      onClick={(e) =>
                                        handleStatusUpdate(e, talent)
                                      }
                                      className={`btn btn-outline text-uppercase font-size-3 ${
                                        talent?.status === "active"
                                          ? "text-red-2"
                                          : "teal"
                                      } focus-reset`}
                                    >
                                      {submitting
                                        ? "..."
                                        : talent?.status === "active"
                                        ? "Disable"
                                        : "Activate"}
                                    </button>
                                    <Link
                                      to={`/candidate-profile/${talent.account_id}`}
                                      state={{ id: talent.account_id }}
                                      className="btn btn-outline text-uppercase font-size-3 text-green focus-reset"
                                    >
                                      Details
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="border border-color-2">
                              <td
                                colSpan={6}
                                className="pl-6 border-0 py-7 pr-0"
                              >
                                <h4 className="font-size-4 mb-0 font-weight-normal text-black-2">
                                  No talents found at the moment
                                </h4>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default DashboardAdminTalents;
